import React from "react"

const InterstitialItem = ({ itemClass, children, ...props }) => (
	<div className="interstitial-item">  
	  <div className={itemClass}>
	  	{children}
	  </div>
  </div>
)

export default InterstitialItem