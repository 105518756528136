import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Img from "gatsby-image"
import HomepageNav from "../components/homepage-nav"

import InterstitialItem from "../components/interstitialitem"
import InterstitialCarousel from "../components/carousel"

import Footer from "../components/footer"

export default ({data}) => {

  const sections = data.sections

  return (
    <Layout>
      <SEO title="Diagram" />

      <HomepageNav/>

      <div className="story-feed">

        <h4 className="homepage-label label">
          People
        </h4>

        <InterstitialItem
          itemClass="carousel"
        >
          {data.people.edges.map(({ node }) => (
            <InterstitialCarousel
              slug={node.fields.slug}
              title={node.frontmatter.title}
              image={node.frontmatter.image.childImageSharp.fluid}
              blurb={node.frontmatter.blurb}
            />
          ))}
        </InterstitialItem>

        <h4 className="homepage-label label">
          Topics
        </h4>

        {sections.edges.map(({ node }) => (

          (node.frontmatter.class == "Topic") ?

            <div className="epic-container">

              <h4 className="label">{node.frontmatter.title}</h4>

              <p>{node.frontmatter.blurb}</p>

              {node.frontmatter.epic.map((section) => (

                <div className="carousel">
                  {data.latestStories.edges.map(({node}) => (
                    (node.frontmatter.epic == section || node.frontmatter.epic.includes(section) ) ?
                      <Link to={node.fields.slug}>
                        <div className="carousel-card">
                          <div className="carousel-card-image">
                            <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                          </div>
                          <div className="carousel-card-text">
                            <h4 className="carousel-card-title">
                              {node.frontmatter.title}
                            </h4>
                            <h5 className="carousel-card-timestamp">
                              {node.frontmatter.date}
                            </h5>
                          </div>
                        </div>
                      </Link>
                    :
                      null
                  ))}
                </div>
              ))}

            </div>

          :

            null

        ))}

      </div>

      <Footer
        className="homepage-footer"
        location="homepage"
      />

    </Layout>
  )
}

export const query = graphql`
  query {

    latestStories: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            in: ["news","explainer"]
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            videoSrcURL
            videoCaption
            epic
            type
            stack
            attachment
            people
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
        }
      }
    }

    sections: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "home"}}}
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            epic
            class
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
          fields {
            slug
          }
        }
      }
    }

    people: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "people"}}}
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 10
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
          fields {
            slug
          }
        }
      }
    }

  }
`