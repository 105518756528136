import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default props =>
  <Link to={props.slug}>
    <div className="carousel-item">
      <div className="carousel-item-image">
        <Img fluid={props.image} />
      </div>
      <div className="carousel-item-text">
        <h4 className="carousel-card-title">
          {props.title}
        </h4>
        <h5 className="carousel-card-timestamp">
          {props.blurb}
        </h5>
      </div>
    </div>
  </Link>